exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-app-jsx": () => import("./../../../src/pages/app.jsx" /* webpackChunkName: "component---src-pages-app-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-festival-jsx": () => import("./../../../src/pages/festival.jsx" /* webpackChunkName: "component---src-pages-festival-jsx" */),
  "component---src-pages-festival-schedule-jsx": () => import("./../../../src/pages/festival-schedule.jsx" /* webpackChunkName: "component---src-pages-festival-schedule-jsx" */),
  "component---src-pages-gallery-jsx": () => import("./../../../src/pages/gallery.jsx" /* webpackChunkName: "component---src-pages-gallery-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-news-jsx": () => import("./../../../src/pages/news.jsx" /* webpackChunkName: "component---src-pages-news-jsx" */),
  "component---src-pages-registration-jsx": () => import("./../../../src/pages/registration.jsx" /* webpackChunkName: "component---src-pages-registration-jsx" */),
  "component---src-pages-schedule-jsx": () => import("./../../../src/pages/schedule.jsx" /* webpackChunkName: "component---src-pages-schedule-jsx" */)
}

