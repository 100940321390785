/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/prefer-default-export */
import React from 'react';
import { ApolloProvider } from '@apollo/client';

// You can delete this file if you're not using it
import './src/styles/style.scss';
import client from './src/gatsby-theme-apollo/client';

export const wrapRootElement = ({ element }) => (
  <>
    <ApolloProvider client={client}>{element}</ApolloProvider>
  </>
);
